<template>
  <div>
    <div :class="{'result-bar-fixed':resultBarFixed}">
      <div class="safe-area flex-row align-items-center justify-between" style="height:60px">
        <div class="color-sub">请尽快确认结算并提交订单，以免因库存变化导致无法下单</div>
        <div class="flex-row align-items-center">
          <div class="margin-right-20">已选商品 <span class="color-orange">￥</span><span class="color-orange bold font-size-20">{{totalAmount | numFormat(2)}}</span></div>
          <el-button type="primary" size="medium" round :disabled="!multipleSelection.length||multipleSelection.some(v=>{return v.pcjcshl<v.sp_buy_sl})" @click="submit">结算</el-button>
        </div>
      </div>
    </div>
    <div style="height: 60px;" v-if="resultBarFixed"></div>
    <div class="safe-area">
      <div class="column" ref="main">
        <el-table
          v-if="cart.list.length"
          ref="cartList"
          :data="cart.list"
          row-key='add_cart_record_id'
          style="width: 100%"
          @selection-change="selectionChange"
        >
        <el-table-column type="selection" :reserve-selection="true" :selectable="selectableFun" width="50">
        </el-table-column>
        <el-table-column label="商品信息"  min-width="200">
          <template slot-scope="props">
            <div class="flex-row link" @click="$root.jump({path:'/mall/prodDetail',query:{q:crypto.encrypt({spid:props.row.spid,hd_id:props.row.hd_id})},blank:true})">
              <div><el-image class="cart-item-pic border" :src="props.row.img_url" fit="contain">
                <div slot="error" class="flex-column flex-auto align-items-center justify-center">
                  <img src="@/assets/images/no-pic.png" height="80px"/>
                </div>
              </el-image></div>
              <div class="flex-auto margin-left-10 font-size-12 line-height-medium">
                <div class="font-size-16 bold margin-bottom-5">{{props.row.spmch}}</div>
                <div class="color-sub">{{props.row.shengccj}}</div>
                <div class="color-sub">{{props.row.shpgg}}</div>
                <div class="color-sub">批号：{{props.row.pihao}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="库存" width="100">
          <template slot-scope="props">{{props.row.pcjcshl}}</template>
        </el-table-column>
        <el-table-column label="单价" width="100">
          <template slot-scope="props">￥{{props.row.sp_buy_jiage}}</template>
        </el-table-column>
        <el-table-column label="数量" width="180">
          <template slot-scope="props">
            <div>
              <el-button size="small" @click="changeNum('reduce',props.row)">-</el-button> 
              <el-input size="small" v-model="props.row.sp_buy_sl" @blur="checkProdNum(props.row)" style="width: 55px; margin: 0 3px;"></el-input> 
              <el-button size="small" @click="changeNum('add',props.row)">+</el-button> 
            </div>
            <div v-if="props.row.sp_buy_sl>props.row.pcjcshl" class="font-size-12 color-red">超过库存，请修改数量</div>
          </template>
        </el-table-column>
        <el-table-column label="金额" width="100">
          <template slot-scope="props"><span class="color-orange">￥{{props.row.sp_buy_jiage*props.row.sp_buy_sl  | numFormat(2)}}</span></template>
        </el-table-column>
        <el-table-column label="添加时间" width="170">
          <template slot-scope="props">{{props.row.add_time}}</template>
        </el-table-column>
        <el-table-column label="操作" align="right" width="100">
          <template slot-scope="props">
            <div class="link-text" @click="removeProduct([props.row])">删除</div>
          </template>
        </el-table-column>
        </el-table>
        <div v-if="cart.loading" class="text-align-center color-sub margin-tb-10"><i class="el-icon-loading"></i> 加载中</div>
        <div v-if="cart.noMore&&cart.list.length" class="text-align-center color-sub margin-tb-10">没有更多啦！</div>
        <el-empty v-if="!cart.list.length&&cart.page==1&&!cart.loading" description="购物车是空的！"></el-empty>
      </div>
      <div :class="{'bottom-result-bar-Fixed':bottomResultBarFixed}">
        <div class="column flex-row align-items-center justify-between">
          <div class="flex-row align-items-center">
            <el-checkbox v-model="selectAllStatus" label="全选" @change="toggleAllSelection" :disabled="!cart.list.length"></el-checkbox>
            <div class="link margin-left-30" @click="removeProduct(multipleSelection)">删除</div>
          </div>
          <div class="flex-row align-items-center">
            <div>已选商品 <span class="color-orange bold font-size-20">{{ multipleSelection.length }}</span> 件</div>
            <div class="margin-lr-20">合计 <span class="color-orange">￥</span><span class="color-orange bold font-size-20">{{totalAmount | numFormat(2)}}</span></div>
            <el-button type="primary" size="medium" round :disabled="!multipleSelection.length||multipleSelection.some(v=>{return v.pcjcshl<v.sp_buy_sl})" @click="submit">结算</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crypto from "@/common/crypto";
export default {
  components: { },
  name: 'Home',
  data() {
    return {
      crypto,
      resultBarFixed:false,
      bottomResultBarFixed:false,
      cart:{
        loading:false,
        noMore:false,
        page:1,
        pageSize:20,
        list:[]
      },
      multipleSelection: [],//选择的商品
      selectAllStatus:false,//全选状态
      bodyScrollHeight:0
    }
  },
  computed:{
    totalAmount(){
      let num=this.multipleSelection.reduce((old,now)=>{
        return old+now.sp_buy_jiage*now.sp_buy_sl
      },0)
      return num
    }
  },
  methods:{
    handleScroll(){
      if(document.documentElement.scrollTop>120){
        this.resultBarFixed=true
      }else{
        this.resultBarFixed=false
      }
      if(this.$refs.main.getBoundingClientRect().bottom>this.$root.docHeight){
        this.bottomResultBarFixed=true
      }else{
        this.bottomResultBarFixed=false
      }
    },
    //获取购物车列表
    getCartList(){
      this.cart.loading=true
      let params= {
        ...this.$store.state.basicParams,
        page:this.cart.page,
        page_size:this.cart.pageSize,
        belonger_user_id:0,
      }
      this.$instance.get('/cart/get_cart_list',{params})
      .then(res=>{
        this.cart.loading=false
        if(res.data.code==0){
          this.cart.list=this.cart.list.concat(res.data.data.products)
          this.$nextTick(()=>{
            window.scrollTo(0,this.bodyScrollHeight)
          })
          //全选
          if(this.multipleSelection.length<this.cart.list.length&&this.selectAllStatus){
            this.$refs.cartList.toggleAllSelection()
          }
          //是否全部加载完成
          if(res.data.data.products.length<this.cart.pageSize){
            this.cart.noMore=true
          }else{this.cart.page+=1}
          //如果有更多增加滚动加载侦听
          if(this.cart.page==2){
            document.addEventListener('scroll', this.loadMore)
          }
        }
      })
      .catch(()=> {
        this.cart.loading=false
      }); 
    },
    //加载更多
    loadMore(){
      this.bodyScrollHeight=document.documentElement.scrollTop || document.body.scrollTop
      this.$root.scrollLoad({
        dom:this.$refs.main,
        allow:!this.cart.loading&&!this.cart.noMore,
        offset:0,
        callBack:this.getCartList,
      })
    },
    //设置购买数量
    changeNum(type,prod){
      let numStep=parseFloat(prod.clbz)||1
      if(type=='reduce'){
        prod.sp_buy_sl-=numStep
        
      }else if(type=='add'){
        prod.sp_buy_sl+=numStep
      }
      this.checkProdNum(prod)
    },
    checkProdNum(prod){
      let numStep=parseFloat(prod.clbz)||1
      if(isNaN(prod.sp_buy_sl)){
        prod.sp_buy_sl=numStep
      }
      else if(prod.sp_buy_sl>=prod.pcjcshl||(prod.pcjcshl==0&&prod.sp_buy_sl<prod.pcjcshl)){
        prod.sp_buy_sl=prod.pcjcshl
      }
      else if(prod.sp_buy_sl<=numStep){
        prod.sp_buy_sl=numStep
      }
      else if(prod.sp_buy_sl%numStep!=0){
        prod.sp_buy_sl=prod.sp_buy_sl-(prod.sp_buy_sl%numStep)
      }
      
    },
    //列表行是否可选
    selectableFun(row){
      return row.pcjcshl>0?true:false
    },
    //选择列表
    selectionChange(val) {
      if(val.length<this.cart.list.length){
        this.selectAllStatus=false
      }else{
        this.selectAllStatus=true
      }
      
      this.multipleSelection = val;
    },
    toggleAllSelection(){
      this.$refs.cartList.toggleAllSelection()
    },
    //删除购物车商品
    removeProduct(items){
      if(!items.length){
         this.$message({message: '请选择要删除的商品',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
         return false;
      }
      let params= {
        ...this.$store.state.basicParams,
        add_cart_record_id:items.map(v=>{return v.add_cart_record_id}).join(','),
        belonger_user_id:0
      }
      this.$instance.post('/cart/remove_product',this.$qs.stringify(params))
      .then(res=>{
        if(res.data.code==0){
          for(let item of items){
            this.cart.list.splice(this.cart.list.findIndex(v=>{return v.add_cart_record_id==item.add_cart_record_id}),1)
          }
          this.multipleSelection=[]
        }
      })
      .catch(()=> {
      }); 
    },
    //结算
    submit(){
      if(!this.multipleSelection.length){
         this.$message({message: '请选择要购买的商品',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
         return false;
      }
      if(this.multipleSelection.some(v=>{return v.sp_buy_sl==0})){
         this.$message({message: '请剔除数量为0的商品或更改购买数量',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
         return false;
      }
      let status=this.multipleSelection.every(v=>{return v.pcjcshl>=v.sp_buy_sl})
      if(status){
        let params= {
          selected_products:this.multipleSelection.map(v=>{return {"add_cart_record_id": v.add_cart_record_id, "spid":v.spid,"pihao": v.pihao, "hd_id": v.hd_id,"sp_buy_sl": v.sp_buy_sl,"sp_buy_jiage": v.sp_buy_jiage}}),
          belonger_user_id:0,
          settlement_type:1
        }
        try{
          sessionStorage.setItem('submitOrderParams',JSON.stringify(params))
          console.log(params)
        }catch{
          //
        }
        this.$store.commit('submitOrderParams',params)
        this.$router.push({
          path:'/submitOrder',
        })
      }else{
        this.$message({message: '所选商品库存不足，请修改购买数量',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      }
    },
  },
  created(){
    this.$store.commit('submitOrderParams',null)
    try{sessionStorage.removeItem('submitOrderParams')}catch{
      //
    }
    this.getCartList()
  },
  mounted(){ 
    document.addEventListener('scroll', this.handleScroll)
    
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("scroll", this.loadMore);
  },
  watch: {
    'cart.list.length'(){
      this.$nextTick(()=>{
        this.handleScroll()
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .result-bar-fixed{
    position: fixed;
    left: 0;
    right: 0;
    top: 40px;
    background: #f7f7f7;
    z-index: 5;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
  }
  .bottom-result-bar-Fixed{position: fixed;
    left: 0;
    right: 0;
    bottom: 0px;
    background: #f7f7f7;
    z-index: 5;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
    .column{
      border-radius: 0;
      margin: 0 auto;
      width: 1200px;
      box-sizing: border-box;
    }
  }
  .cart-item-pic{
    width: 80px;
    height: 80px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
  }

  </style>
<style>

</style>
